<template>
    <b-modal
      v-model="data.showModal"
      size="md"
      button-size="sm"
      modal-class="template-modal"
      title-class="w-full"
      footer-class="w-full"
      no-fade
      @show="onShow"
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      scrollable
    >
      <!-- Modal header -->
      <div slot="modal-title">
        <div v-if="data.groupid === null">
          New group
        </div>
        <div v-else class="flex gap-1 w-full text-lg">
          <span>Edit group -</span>
          <span class="font-bold">{{ group.Name }}</span>
        </div>
      </div>

      <!-- Modal content -->
      <div class="flex flex-col gap-2">
        <table class="table table-google">
          <thead>
            <tr>
              <th colspan="2">Group details</th>
            </tr>
          </thead>
          <tbody v-if="groupReady">
            <tr>
              <td>Name</td>
              <td>
                <b-form-input
                  v-model="group.Name"
                  placeholder="Name"
                  size="sm"
                  autocomplete="off"
                  trim
                  :disabled="isLoading"
                  autofocus
                ></b-form-input>
              </td>
            </tr>

            <tr v-if="data.groupid !== 0">
              <td class="align-top">Roles</td>
              <td>
                <div class="flex flex-col gap-2">
                  <b-button
                    v-for="permit in options.Permits"
                    :key="`permit_${permit.Id}`"
                    :variant="getPermitVariant(permit)"
                    size="sm"
                    class="w-100"
                    @click="togglePermit(permit)"
                  >
                    <span>{{ permit.Name }}</span>
                  </b-button>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="2">
                Loading group..
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Modal footer -->
      <div slot="modal-footer" class="w-full d-flex gap-4 justify-content-between items-center">
        <div class="flex w-full justify-content-between">
          <div>
            <b-btn
              size="sm"
              variant="primary"
              :disabled="isLoading || !canSave"
              @click="onSave"
            >
              Save
            </b-btn>
          </div>
          <div>
            <b-btn
              size="sm"
              variant="white"
              :disabled="isLoading"
              @click="onClose"
            >
              Close
            </b-btn>
          </div>
        </div>
      </div>
    </b-modal>
  </template>

<script>
export default {
  name: 'UserGroupModal',
  components: {},
  props: {
    data: Object,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
    canSave() {
      if (this.group.Name.length === 0) {
        return false;
      }
      return true;
    },
  },
  data() {
    return {
      loadingCount: 0,
      group: {
        Name: '',
        Permits: [],
      },
      groupReady: false,
      filters: {},
      options: {
        Permits: [],
      },
    };
  },
  methods: {
    hasPermit(permit) {
      return this.group.Permits.includes(permit.Id);
    },
    getPermitVariant(permit) {
      if (this.hasPermit(permit)) {
        return 'primary';
      }
      return 'light';
    },
    togglePermit(permit) {
      if (this.hasPermit(permit)) {
        this.deletePermit(permit);
      } else {
        this.addPermit(permit);
      }
    },
    addPermit(permit) {
      this.group.Permits.push(permit.Id);
    },
    deletePermit(permit) {
      this.group.Permits = this.group.Permits.filter((item) => item !== permit.Id);
    },
    fetchPermits() {
      this.loadingCount++;
      this.$http
        .post('/rpc')
        .send({ api: 'user' })
        .send({ method: 'permits' })
        .then((res) => {
          this.options.Permits = res.body;
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchGroup() {
      if (this.data.groupid === null) {
        this.groupReady = true;
        return;
      }
      this.loadingCount++;
      this.$http
        .get(`/groups/${this.data.groupid}`)
        .then((res) => {
          this.group = res.body.group;
          this.groupReady = true;
        })
        .catch((err) => {
          console.error(err);
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchAll() {
      this.fetchPermits();
      this.fetchGroup();
    },
    onSave() {
      this.loadingCount++;
      this.$http
        .post(`/groups/${this.data.groupid}`)
        .send({ data: this.group })
        .then(() => {
          this.$emit('saved');
          this.onClose();
        })
        .catch((err) => {
          this.$toast.error(err.response.text);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    onShow() {
      this.fetchAll();
    },
    onClose() {
      this.data.showModal = false;
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
</style>
